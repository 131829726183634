import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import NewsListClient from '../components/newsListClient'
import BackgroundImage from 'gatsby-background-image'
import NewsListClientMultiple from '../components/newsListClientMultiple'

const NewsPage = () => {
    const data = useStaticQuery(graphql`
        query { 
            news: allMarkdownRemark (
                filter: { frontmatter: {templateKey: {eq: "news"}} },
                sort: { fields: frontmatter___order order: DESC }
            ) {
            edges {
            node {
                frontmatter {
                title
                thumbnail {
                    childImageSharp {
                    fluid(maxWidth:800, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                    }
                }
                client_rel
                order
                }
                html
            }
            }
        }
        clients: allMarkdownRemark (filter: {frontmatter: {templateKey: {eq: "client"}}}) {
            edges {
            node {
                frontmatter {
                fullName
                portfolio_boolean
                spotlight_cv
                thumbnail {
                    childImageSharp {
                    fluid(maxWidth:300) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                    }
                }
                }
                fields {
                    slug
                }
            }
            }
        }

    }
    `)

    // const newsSorted = data.news.edges.sort((a,b) => {
    //     return a.node.frontmatter.order - b.node.frontmatter.order
    // })
    // console.log(newsSorted)
    
    return (
        <Layout>
        <SEO title="Latest News"/>
        
        <section className="section news-list">
        <h1>Latest News</h1>
        {
            data.news.edges.map( (item) => {
                //filter clients          
                const clients = data.clients.edges.filter( (client) => {
                    //return item.node.frontmatter.client_rel === client.node.frontmatter.fullName
                    return item.node.frontmatter.client_rel.includes(client.node.frontmatter.fullName)
                })
                // let client_link = undefined
                //     if (client.node.frontmatter.portfolio_boolean) {
                //         client_link = 'profile'
                //     } else if (client.node.frontmatter.spotlight_cv) {
                //         client_link = 'spotlight'
                //     } else {
                //         client_link = false
                //     }
                
                return(
                    <div className="news-item columns" key={item.node.frontmatter.title}>
                    <div className="news-item-image column is-half ">
                            
                            <BackgroundImage
                                Tag="div"
                                fluid={item.node.frontmatter.thumbnail.childImageSharp.fluid}
                                backgroundColor={`#040e18`}
                                alt={item.node.frontmatter.title}
                                />
                        </div>    
                    
                    <div className="news-item-text column is-half">
                            <h1>{item.node.frontmatter.title}</h1>
                            <div dangerouslySetInnerHTML={{__html: item.node.html}}></div>
                            {/*<NewsListClient client={client} client_link={client_link}/>*/}
                            <NewsListClientMultiple clients={clients}/>
                        </div>
                        
                        
                        
                    </div>
                )
            })
        }
        </section>
        </Layout>
    )
}

export default NewsPage