import React from 'react'
//import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import {Link} from 'gatsby'
import NewsListClient from '../components/newsListClient'


const newsListClientMultiple = ({clients}) => {
    console.log(clients)
    return(
        <div>
        {

        clients.map((client) => {
            let client_link = undefined
            if (client.node.frontmatter.portfolio_boolean) {
                client_link = 'profile'
            } else if (client.node.frontmatter.spotlight_cv) {
                client_link = 'spotlight'
            } else {
                client_link = false
            }
            return(
                <div className="news-clients">
                <NewsListClient client={client} client_link={client_link} />
                </div>
            )
        })

        }
        </div>
    )
}

export default newsListClientMultiple