import React from 'react'
//import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import {Link} from 'gatsby'



const newsListClient = ({client,client_link}) => {
   
    return(
        <div key={client.node.frontmatter.fullName} className="news-client">
            <div className="news-client-name ">
                {client.node.frontmatter.fullName}
            </div>
            <div className="news-client-image">
              
            {
                client_link === 'profile' &&
                <Link to={`/clients/${client.node.fields.slug}`}>
                
                <BackgroundImage
                tag="div"
                fluid={client.node.frontmatter.thumbnail.childImageSharp.fluid}
                alt={client.node.frontmatter.fullName}
                />
                </Link>
            }
            {
                client_link === 'spotlight' &&
                <a href={client.node.frontmatter.spotlight_cv} target="_blank">
                <BackgroundImage
                tag="div"
                fluid={client.node.frontmatter.thumbnail.childImageSharp.fluid}
                alt={client.node.frontmatter.fullName}
                />
                </a>
            }

            {
                !client_link  &&
                <>
                <BackgroundImage
                tag="div"
                fluid={client.node.frontmatter.thumbnail.childImageSharp.fluid}
                alt={client.node.frontmatter.fullName}
                />
               </>
            }
            
            
            </div>
        </div>
    )
}

export default newsListClient